import React from 'react';
// import { Link } from 'react-router-dom';
import "./resources-res.scss";

// assets
import logoBig from "../../../assets/fb360logo-big.png";
import pin from "../../../assets/icon-pin.png";
import phone from "../../../assets/icon-phone.png";
import mail from "../../../assets/icon-mail.png";
import banner_right from "../../../assets/banner_right_bottom.png";
import banner_left from "../../../assets/banner_left_top.png";
import resources1 from "../../../assets/resources/resources1.png";
import resources2 from "../../../assets/resources/resources2.png";
import resources_right from "../../../assets/resources/resources_right.png";
import resources_bg from "../../../assets/resources/resources-bg.png";
import contact_us from "../../../assets/contact_us.png";
import Global from '../../../Global';

class ResourcesRes extends React.Component  {
    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            email: '',
            message: ''
        };
    }

    handleSubmit = (event) => {
        fetch(Global.WEB_URL + '/api/contact', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function(response) {
            alert('Thank you for submitting your idea.');

            console.log(response)
            return response.json();
        });

        event.preventDefault();
    }
    render(){
        return (
        <div>
            <div className="banner" style={{backgroundImage: "url(" + resources_bg + ")"}}>
                <div className="container">
                    <div className="learn-more">
                        <div className="dots-bg"></div>
                        <div className="learn-title">Resources</div>
                        <div className="learn-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Have questions? We’ve got answers. Find what you need quickly!</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bannerRight">
                    <img src={banner_right} alt="Banner right"/>
                </div>
                <div className="bannerLeft">
                    <img src={banner_left} alt="Banner left"/>
                </div>
            </div>
            
            <section className="resources">
                <div className="resources-right">
                    <img src={resources_right} alt="Resources right"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="borderGreen"></div>

                            <div className="title-block">
                                Support Resources
                            </div>

                            <ul className="resources-list">
                                <li>
                                    <a href="#">
                                        <i>
                                            <img src={resources1} alt="Help Center" />
                                        </i>
                                        <span>Help Center</span>
                                    </a>
                                </li>

                                <li>
                                    <a href="#">
                                        <i>
                                            <img src={resources2} alt="Feedback360 Blog" />
                                        </i>
                                        <span>Feedback360 Blog</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-5">
                            <div className="borderGreen"></div>

                            <div className="title-block">
                                Get in Touch
                            </div>

                            <p>
                                Still need help? Don’t be shy to reach out!
                            </p>

                            <p>
                                Our team is at your service. If you’d like an awesome product tour, request a personzlied demo! They are also available for other questions!
                            </p>

                            <ul className="resources-buttons">
                                <li>
                                    <a href="https://calendly.com/feedback360/book-your-free-demo-of-feedback360">Book Your Free Demo</a>
                                </li>

                                <li>
                                    <a href="#">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div id="contact-section-mobile" className="contact-mobile d-flex flex-column">
                <div className="form-contact">
                    <div className="borderGreen" style={{margin:'0px'}}></div>
                    <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                    <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                    <form onSubmit={this.handleSubmit}>
                            <input type="text" name="name" className="form-control" placeholder="Your name" value={this.state.value} onChange={this.handleChange}/>
                            <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.value} onChange={this.handleChange}/>
                            <textarea className="form-control" name="message" rows="6" placeholder="Message" value={this.state.value} onChange={this.handleChange}></textarea>
                            <input type="submit" className="btn submit-btn" value="Send" />
                        </form>
                </div>
                <div className="address">
                    <div className="list-contact d-flex flex-row">
                        <img src={contact_us} alt="Contact us"/>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

export default ResourcesRes;