import React, { Fragment } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Responsive from "../../components/responsive/checkout/checkout-res"
import Menu from "../../components/menu/menu";
import "./checkout.scss"
import { Link } from "react-router-dom";

class Checkout extends React.Component {
    state = {
        step1: false,
        step2: false,
        step3: false,
        selected: 'test1'
    }

    render() {
        return (
            <Fragment>
                <Header/>
                <div className="container d-none d-lg-block">
                    <div className="checkout">
                        <div className="check-title">Checkout</div>
    
                        <div className="step-tabs d-flex justify-content-between">
                            <div className={`step-item ${this.state.step1? null : "active"}`}>
                                Select Package <i className={this.state.step1? "fas fa-check-circle" : null}></i>
                            </div>
                            <div className={`step-item ${this.state.step2? "active" : null }`}>
                                Package details <i className={this.state.step3? "fas fa-check-circle" : null}></i>
                            </div>
                            <div className={`step-item ${this.state.step3? "active" : null }`}>
                                Confirmation <i className=""></i>
                            </div>
                        </div>
    
                        <div className="step-contents">
                            <div className={`content-item ${this.state.step1? "hiding" : "showing"}`} id="step1">
                                <div className="content-title">Select your package</div>
                                <div className="content-body">
                                    <div className="package-checkout">
                                        <div className="package-cards d-flex flex-wrap justify-content-between mx-auto">
                                            <div className="d-flex flex-column single-package">
                                                <div className="package-head d-flex align-items-center">
                                                    <div className="mx-auto">Individual</div>
                                                </div>
                                                <div className="package-contain">
                                                    <div className="package-type">Applications</div>
                                                    <div className="package-list">
                                                        <ul>
                                                            <li>Feedback for personal development</li>
                                                            <li>Understand the needs of others</li>
                                                            <li>Explore what is needed by others for their success</li>
                                                            <li>Monitor the progress of others & yourself</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="package-button mt-auto d-grid">
                                                    <button className="btn" onClick={() => {this.setState({step1: !this.state.step1}); this.setState({step2: !this.state.step2})}}>Select Package</button>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column single-package">
                                                <div className="package-head d-flex align-items-center">
                                                    <div className="mx-auto">Team</div>
                                                </div>
                                                <div className="package-contain">
                                                    <div className="package-type">Applications</div>
                                                    <div className="package-list">
                                                        <ul>
                                                            <li>Focus - purpose, objectives & priorities</li>
                                                            <li>Team review</li>
                                                            <li>How are we working together?</li>
                                                            <li>Collect everyone’s voice on issues</li>
                                                            <li>Collect perspectives</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="package-button mt-auto d-grid">
                                                    <button className="btn" onClick={() => {this.setState({step1: !this.state.step1}); this.setState({step2: !this.state.step2})}}>Select Package</button>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column single-package">
                                                <div className="package-head d-flex align-items-center">
                                                    <div className="mx-auto">Idea / Issue</div>
                                                </div>
                                                <div className="package-contain">
                                                    <div className="package-type">Applications</div>
                                                    <div className="package-list">
                                                        <ul>
                                                            <li>Ensure understanding</li>
                                                            <li>Collect different perspectives</li>
                                                            <li>Stakeholder views & input</li>
                                                            <li>Challenge & add to ideas</li>
                                                            <li>Review implications & execution issues</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="package-button mt-auto d-grid">
                                                    <button className="btn" onClick={() => {this.setState({step1: !this.state.step1}); this.setState({step2: !this.state.step2})}}>Select Package</button>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column single-package">
                                                <div className="package-head d-flex align-items-center">
                                                    <div className="mx-auto">Startup Offering</div>
                                                </div>
                                                <div className="package-contain">
                                                    <div className="package-type">Applications</div>
                                                    <div className="package-list">
                                                        <ul>
                                                            <li>Test startup proposition</li>
                                                            <li>Collect key stakeholder perspectives</li>
                                                            <li>Team forming & review</li>
                                                            <li>Founder feedback & support</li>
                                                            <li>Customer insights</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="package-button mt-auto d-grid">
                                                    <button className="btn" onClick={() => {this.setState({step1: !this.state.step1}); this.setState({step2: !this.state.step2})}}>Select Package</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className={`content-item ${this.state.step2? "showing" : "hiding"}`} id="step2">
                                <div className="content-title">Set up your Package details</div>
                                <div className="content-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Package</th>
                                                <th scope="col">Package selection</th>
                                                <th scope="col">Package details</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="package-title">Team</div>
                                                    <div className="package-type">Application</div>
                                                    <div className="package-desc">
                                                        <ul>
                                                            <li>Lorem ipsum</li>
                                                            <li>Dolor sit amet</li>
                                                            <li>Consectetur</li>
                                                            <li>Adipiscing</li>
                                                            <li>Aliquam at eu</li>
                                                            <li>Integer a hendrerit</li>
                                                            <li>Habitasse erat</li>
                                                            <li>Venenatis</li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className="check-contain">
                                                    <ul className="unstyled">
                                                        <li>
                                                        <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
                                                        <label htmlFor="styled-checkbox-1">Individuals, Team leads and Startup founder</label>
                                                        </li>
                                                        <li>
                                                        <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" defaultChecked={true}/>
                                                        <label htmlFor="styled-checkbox-2">Coaches</label>
                                                        </li>
                                                        <li>
                                                        <input className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4"/>
                                                        <label htmlFor="styled-checkbox-4">Workshop programme managers</label>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="radio-contain">
                                                    <ul className="unstyled">
                                                        <li className="span-counter">
                                                            <input type="radio" id="test1" name="radio-group" value="test1" checked={this.state.selected === 'test1'} onChange={(e) => this.setState({ selected: e.target.value })}/>
                                                            <label htmlFor="test1">
                                                                <div className="d-flex">
                                                                    <div className="radio-label">Generic</div>
                                                                    <div className="qty d-flex align-items-center justify-content-between">
                                                                        <span className="minus">-</span>
                                                                        <input type="number" className="count" name="qty" value="1" disabled/>
                                                                        <span className="plus">+</span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </li>
                                                        <li className="span-counter-dis">
                                                            <input type="radio" id="test2" name="radio-group" value="test2" checked={this.state.selected === 'test2'} onChange={(e) => this.setState({ selected: e.target.value })}/>
                                                            <label htmlFor="test2">
                                                                <div className="d-flex">
                                                                    <div className="radio-label">Bundle</div>
                                                                    <div className="qty d-flex align-items-center justify-content-between">
                                                                        <span className="minus">-</span>
                                                                        <input type="number" className="count" name="qty" value="1" disabled/>
                                                                        <span className="plus">+</span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="test3" name="radio-group" value="test3" checked={this.state.selected === 'test3'} onChange={(e) => this.setState({ selected: e.target.value })}/>
                                                            <label htmlFor="test3">
                                                                <div className="radio-label">Question 1</div>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="test4" name="radio-group" value="test4" checked={this.state.selected === 'test4'} onChange={(e) => this.setState({ selected: e.target.value })}/>
                                                            <label htmlFor="test4">
                                                                <div className="radio-label">Question 2</div>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                    
                                                </td>
                                                <td className="span-counter">
                                                    <div className="qty d-flex align-items-center justify-content-between">
                                                        <span className="minus">-</span>
                                                        <input type="number" className="count" name="qty" value="1" disabled/>
                                                        <span className="plus">+</span>
                                                    </div>
                                                </td>
                                                <td>S$ 25.00</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Subtotal</td>
                                                <td>S$ 25.00</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <div className="table-btn d-flex justify-content-between">
                                    <button id="back-btn" className="btn" onClick={() => {this.setState({step2: !this.state.step2}); this.setState({step1: !this.state.step1})}}>Back</button>
                                    <button id="confirm-btn" className="btn nav-link" onClick={() => {this.setState({step2: !this.state.step2}); this.setState({step3: !this.state.step3})}}>Confirm to Payment</button>
                                </div>
                            </div>

                            <div className={`content-item ${this.state.step3? "showing" : "hiding"}`} id="step3">
                                <div className="content-title">Set up your details and Payment method</div>
                                <div className="content-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="p-details">Package details</div>
                                        <div className="p-edit"><a href="#">Edit package</a></div>
                                    </div>

                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Package</th>
                                                <th scope="col">Package selection</th>
                                                <th scope="col">Package details</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="package-title">Team</div>
                                                </td>
                                                <td>
                                                    Coaches
                                                </td>
                                                <td>
                                                    Generic / 1
                                                </td>
                                                <td>
                                                    1
                                                </td>
                                                <td>S$ 25.00</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Subtotal</td>
                                                <td>S$ 25.00</td>
                                            </tr>
                                            <tr>
                                                <td id="notes" colSpan="5">
                                                    <div className="notes-title">Notes:</div>
                                                    <div className="notes-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius id id lacus, elementum, commodo, nisi vitae cursus eget. Imperdiet dui fringilla nibh aliquet suscipit. Ullamcorper nulla purus congue non diam enim ullamcorper turpis.</div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <div className="details-method d-flex justify-content-between">
                                        <div className="user-details">
                                            <div className="title">User details<span>*</span></div>
                                            <div className="box-form">
                                                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius id id lacus, elementum, commodo.</div>
                                                <form>
                                                    <input type="text" className="form-control" id="fullname" placeholder="Name"/>
                                                    <input type="text" className="form-control" id="address" placeholder="Address"/>
                                                    <input type="number" className="form-control" id="phone" placeholder="Phone"/>
                                                    <input type="email" className="form-control" id="email" placeholder="Email"/>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="payment-promo d-flex flex-column">
                                            <div className="promo-code mb-5">
                                                <div className="title">Promo code</div>
                                                <div className="box-form">
                                                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius id id lacus, elementum, commodo.</div>
                                                    <form>
                                                        <input type="text" className="form-control" id="promocode" placeholder="Enter promo code"/>
                                                    </form>
                                                    <div><button className="btn">Apply promo code</button></div>
                                                </div>
                                            </div>
                                            <div className="payment-method">
                                                <div className="title">Payment method<span>*</span></div>
                                                <div className="box-form">
                                                    <ul className="nav nav-pills d-flex justify-content-around" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <a className="nav-link active" id="debitcredit-tab" data-bs-toggle="pill" href="#debit-credit" role="tab" aria-controls="debit-credit" aria-selected="true">Credit / Debit card</a>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <a className="nav-link" id="paypal-tab" data-bs-toggle="pill" href="#paypal" role="tab" aria-controls="paypal" aria-selected="false">Paypal</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade show active" id="debit-credit" role="tabpanel" aria-labelledby="debitcredit-tab">
                                                            <form>
                                                                <input type="text" className="form-control" id="nameoncard" placeholder="Name on card"/>
                                                                <input type="number" className="form-control" id="cardnumber" placeholder="Card number"/>
                                                                <div className="d-flex flex-row">
                                                                    <input type="number" className="form-control" id="expmonth" placeholder="Exp month"/>
                                                                    <input type="number" className="form-control" id="strip" placeholder="-"/>
                                                                    <input type="number" className="form-control" id="expyear" placeholder="Exp year"/>
                                                                    <input type="number" className="form-control" id="cvvnumber" placeholder="CVV"/>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div className="tab-pane fade" id="paypal" role="tabpanel" aria-labelledby="paypal-tab">
                                                            <form>
                                                                Content
                                                            </form>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-btn d-flex justify-content-between">
                                        <button id="back-btn" className="btn" onClick={() => {this.setState({step3: !this.state.step3}); this.setState({step2: !this.state.step2})}}>Back</button>
                                        <Link to="/success">
                                        <button id="confirm-btn" className="btn">Confirm to Payment</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-block d-lg-none">
                    <Responsive />
                    <Menu />
                </div>
                <Footer/>
            </Fragment>
        )
    }
    
}

export default Checkout;