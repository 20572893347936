import React from 'react';
// import { Link } from 'react-router-dom';
import "./home-res.scss";

// assets
import laptop from "../../../assets/fb360laptop-full.png";
import feature1 from "../../../assets/feature-1.png";
import feature2 from "../../../assets/feature-2.png";
import feature3 from "../../../assets/feature-3.png";
import feature4 from "../../../assets/feature-4.png";
import feature5 from "../../../assets/feature-5.png";
import feature6 from "../../../assets/feature-6.png";
// import mainImg from "../../../assets/main-sm-2.png";
// import logoBig from "../../../assets/fb360logo-big.png";
// import pin from "../../../assets/icon-pin.png";
// import phone from "../../../assets/icon-phone.png";
// import mail from "../../../assets/icon-mail.png";
import contact_us from "../../../assets/contact_us.png";

import banner_right from "../../../assets/banner_right_bottom.png";
import banner_left from "../../../assets/banner_left_top.png";
import focus_point from "../../../assets/focus_point.png";
import package_right from "../../../assets/package_bottom_right.png";
import package_left from "../../../assets/package_top_left.png";
import Global from '../../../Global';

class HomeRes extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            email: '',
            message: ''
        };
    }

    handleSubmit = (event) => {
        var _this = this;
        fetch(Global.WEB_URL + '/api/contact', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function(response) {
            alert('Thank you for submitting your idea.');
            _this.setState({ 
                name: '',
                email: '',
                message: ''
            });
            console.log(response)
            return response.json();
        });

        event.preventDefault();
    }

    render(){
        return (
            <div>
                <div className="banner-res">
                    <div className="d-flex flex-column">
                        <div className="learn-more d-flex flex-column justify-content-center">
                            <div className="learn-title">Are you looking for <span>viewpoints</span> from others?</div>
                            <div className="learn-desc">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Understanding how others are experiencing your leadership may help focus your development plan.</li>
                                    <li className="list-group-item">Maybe you manage a team and want to gather some insights in a safe and authentic way?</li>
                                    <li className="list-group-item">Or maybe you are leading an initiative at work or have a startup offering that you’re looking to get some valued opinions on before you go any further?</li>
                                </ul>
                            </div>
                            <div className="learn-button align-self-center">
                                <a className="btn" href="#learn">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className="bannerRight">
                        <img src={banner_right}  alt="Banner right"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={banner_left}  alt="Banner left"/>
                    </div>
                </div>

                <div id="learn" className="viewpoint-sm">
                    <div className="d-flex flex-column align-items-center">
                        {/*<div className="ellipse1 rounded-circle"></div>*/}
                        <div className="collecting">
                            <div className="borderGreen"></div>
                            <div className="perspective">
                                <div className="p-title">Collecting <span>Viewpoints</span> Made Easy</div>
                                <div className="p-desc">Collecting Perspectives.  Including Different Voices &#38; Their Experiences.  Starting Point for Dialogue.  </div>
                            </div>
                            
                            <div className="laptop-img">
                                <img src={laptop} alt="Laptop"/>
                                <div className="ellipse2 rounded-circle"></div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>

                <div id="features-mobile" className="feature-sm">
                    <div className="borderGreen"></div>
                    <div className="feature-title">Features</div>
                    <div className="dots-bg"></div>
                    <div className="feature-cards d-flex flex-column">
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature1} alt="Feature 1"/></div>
                            <div className="single-title">Flexible & Nimble</div>
                            <div className="single-desc">Choice of questions and rater categories.  Easy and quick to use for you and for those contributing</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature2} alt="Feature 2"/></div>
                            <div className="single-title">Inexpensive</div>
                            <div className="single-desc">Very reasonable pricing with incentive for multiple use during a period of time that could represent journey during a development, growth or change stage.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature3} alt="Feature 3"/></div>
                            <div className="single-title">No Certification</div>
                            <div className="single-desc">No requirement for expensive certification training – though is a tool enhanced in the hands of good coaches.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature4} alt="Feature 4"/></div>
                            <div className="single-title">Progress Monitoring</div>
                            <div className="single-desc">You can monitor the progress of completed questionnaires without jeopardising the anonymity of raters.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature5} alt="Feature 5"/></div>
                            <div className="single-title">Question Databank</div>
                            <div className="single-desc">We have a growing databank of sets of useful, well asked questions. While qualitative questions are sought often more than the quantitative ones, both can be used. The magic is in how the questions are asked.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature6} alt="Feature 6"/></div>
                            <div className="single-title">Mobile Devices</div>
                            <div className="single-desc">If your device receives emails, you can use your mobile devices to complete the questionnaires and see reports.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature1} alt="Feature 1"/></div>
                            <div className="single-title">Context Relevant</div>
                            <div className="single-desc">Choice of focus area seeking different perspectives or feedback - Individuals, Teams, Ideas/Concepts or Startups - along with an input field to define your precise context thus helping your contributors to provide relevant input.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature2} alt="Feature 2"/></div>
                            <div className="single-title">Anonymity Management</div>
                            <div className="single-desc">Within each contributor (or rater) group, minimum number of responses required can be set and aggregated results are presented on the report. If one is the minimum, that person is specifically informed that this is the case.</div>
                        </div>
                        <div className="single-feature mx-auto">
                            <div className="single-img"><img src={feature3} alt="Feature 3"/></div>
                            <div className="single-title">Ease of Accessibility</div>
                            <div className="single-desc">Individuals, leaders, managers, founders can themselves buy and initiate a carefully chosen questionnaire without having to use an intermediary. Coaches also has ease of access for their clients.</div>
                        </div>
                    </div>
                    <div className="dots-bg-btm"></div>
                </div>

                <div id="focuspoint-mobile" className="focuspoint-mobile">
                    <div className="focus-cards d-flex flex-wrap justify-content-between">
                        <div className="single-feature">
                            <img src={focus_point} alt="Focus point"/>
                        </div>
                        <div className="single-feature single-center">
                            <div className="explanation">
                                <div className="borderGreen" style={{margin:'0px'}}></div>
                                <div className="p-title"><span>Focus</span> Points</div>
                                <div className="p-desc">
                                    <p>
                                    Making the collecting of viewpoints, perspectives, insights, experiences and feedback easy, useful and relevant given a particular context or focus is what we seek to do. This can provide a positive cornerstone to constructive and inclusive dialogue where progress is sought. We have organized these focus points to help guide you as you select the questionnaire needed to kickstart your conversation: individual, team, idea/issue, start-up.
                                    </p>
                                    <div className="learn-button">
                                        <a className="btn" href="#learn">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="packages-mobile" className="package-sm">
                    {/*<div className="ellipse6 rounded-circle"></div>*/}
                    <div className="d-flex flex-column align-items-center">
                        <div className="borderGreen"></div>
                        <div className="package-desc"><span>Choose the plan</span> that suits your needs</div>
                        <div className="package-from">Start your free trial today.</div>
                    </div>
                    <div className="package-cards d-flex flex-wrap justify-content-between mx-auto">
                        <div className="d-flex flex-column single-package">
                            <div className="package-head d-flex align-items-center">
                                <div className="mx-auto">Individual</div>
                            </div>
                            <div className="package-contain">
                                <div className="package-list">
                                    <ul>
                                        <li>Questionnaire choice</li>
                                        <li>Set-up/monitor for self</li>
                                        <li>Explore what is needed by others for their success</li>
                                        <li>Access to personal reports</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="package-button mt-auto mx-auto">
                                <a className="btn" href="/checkout">See Details</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column single-package package-active">
                            <div className="package-head d-flex align-items-center">
                                <div className="mx-auto">Coach</div>
                            </div>
                            <div className="package-contain">
                                <div className="package-list">
                                    <ul>
                                        <li>Corporate features</li>
                                        <li>Questionnaire choice including coach exclusives</li>
                                        <li>Questionnaire contributor status benefits</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="package-button mt-auto mx-auto">
                                <a className="btn active" href="/checkout">See Details</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column single-package">
                            <div className="package-head d-flex align-items-center">
                                <div className="mx-auto">Corporate</div>
                            </div>
                            <div className="package-contain">
                                <div className="package-list">
                                    <ul>
                                        <li>Individual features</li>
                                        <li>Set-up/monitor for others</li>
                                        <li>Batch management (e.g. workshops)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="package-button mt-auto mx-auto">
                                <a className="btn" href="/checkout">See Details</a>
                            </div>
                        </div>
                    </div>
                    {/*<div className="ellipse7 rounded-circle"></div>*/}
                    <div id="contact-section-2"></div>
                    <div className="bannerRight">
                        <img src={package_right}  alt="{Package right}"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={package_left}  alt="Package left"/>
                    </div>
                </div>

                <div id="contact-section-mobile" className="contact-mobile d-flex flex-column">
                    <div className="form-contact">
                        <div className="borderGreen" style={{margin:'0px'}}></div>
                        <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                        <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                        <form onSubmit={this.handleSubmit}>
                            <input type="text" name="name" className="form-control" placeholder="Your name" value={this.state.name} onChange={this.handleChange}/>
                            <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                            <textarea className="form-control" name="message" rows="6" placeholder="Message" value={this.state.message} onChange={this.handleChange}></textarea>
                            <input type="submit" className="btn submit-btn" value="Send" />
                        </form>
                    </div>
                    <div className="address">
                        <div className="list-contact d-flex flex-row">
                            <img src={contact_us} alt="Contact us"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeRes;