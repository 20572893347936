import React from "react";
import "./login-modal.scss";
import login_left from "../../../assets/login_back.png";
import logo from "../../../assets/fb360logo-big.png";
import logo_google from "../../../assets/login-google.png";
import logo_facebook from "../../../assets/login-facebook.png";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Global from '../../../Global';

class LoginModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = { 
          password: '',
          email: '',
          password_visible: false,
          csrf: ''
      };
  }
   
  handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
    alert(Global.WEB_URL);
    /*fetch('http://feedback360.appreneurs.co/api/login', {
        method: 'POST',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify(this.state)
    }).then(function(response) {
        console.log(response)
        return response.json();
    });*/

    event.preventDefault();
    //return false;
  }

  render() {
    return (
        <Modal 
          classNames={{
            modal: 'modal',
          }}
          center
          open={this.props.open} 
          onClose={this.props.onClose}>
            <div id="login-section" className="login d-flex flex-row">
                <div className="loginLeft">
                    
                    <div className="textModalTitle">
                        Relevant<br />
                        For<br />
                        Growth.
                    </div>
                </div>
                <div className="form-login">
                    <img src={logo} />
                    <div className="login-title">Log in</div>
                    <form action={`${Global.WEB_URL}/admin/auth/try_login`} method="POST" acceptCharset="utf-8">
                        <div className="login-subtitle">Email address</div>
                        <input type="email" name="u_email" className="form-control" placeholder="Enter your email" value={this.state.value} onChange={this.handleChange}/>

                        <div className="login-subtitle">Password</div>
                        <div className="login-password-wrap d-flex flex-row">
                            <input type={this.state.password_visible ? "text" : "password"} name="u_password" className="form-control" placeholder="Enter your password" value={this.state.value} onChange={this.handleChange}/>
                            <i onClick={()=>{this.setState({password_visible: !this.state.password_visible})}} class={this.state.password_visible ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                        </div>
                        <input type="submit" className="btn submit-btn" value="Log in" />
                    </form>
                    <div className="forgot-password"><a href={`${Global.WEB_URL}/admin/auth/forgot_password`} target="_blank">Forgot password?</a></div>
                    <div className="signup">New to Feedback360? <span onClick={this.props.goToPreSignUp}>Sign up</span></div>
                </div>
            </div>
        </Modal>
    );
  }
};

export default LoginModal;