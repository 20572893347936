import React, { Fragment } from 'react';
import "./faq.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Contact from "../../components/contact/contact";
import Responsive from "../../components/responsive/resources/resources-res";
import Menu from "../../components/menu/menu";

// assets
import banner_right from "../../assets/banner_right_bottom.png";
import banner_left from "../../assets/banner_left_top.png";
import resources1 from "../../assets/resources/resources1.png";
import resources2 from "../../assets/resources/resources2.png";
import resources_right from "../../assets/resources/resources_right.png";
import resources_bg from "../../assets/resources/resources-bg.png";

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className="accordion-wrapper">
      
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
        >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

const Faq = () => {
    return (
        <Fragment>
            <Header />
            <div className="d-none d-lg-block">
                <div className="banner" style={{backgroundImage: "url(" + resources_bg + ")"}}>
                    <div className="container">
                        <div className="learn-more">
                            <div className="dots-bg"></div>
                            <div className="learn-title">FAQ</div>
                        </div>
                    </div>
                    <div className="bannerRight">
                        <img src={banner_right} alt="Banner right"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={banner_left} alt="Banner left"/>
                    </div>
                </div>

                <section className="resources">
                    <div className="resources-right">
                        <img src={resources_right} alt="Resources right"/>
                    </div>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12">
                              <div className="wrapper">
                                <Accordion title="Why is the sky blue?">
                                  Sunlight reaches Earth's atmosphere and is scattered in all directions by
                                  all the gases and particles in the air. Blue light is scattered more than
                                  the other colors because it travels as shorter, smaller waves. This is why
                                  we see a blue sky most of the time.
                                </Accordion>
                                <Accordion title="What's It Like Inside Jupiter?">
                                  It's really hot inside Jupiter! No one knows exactly how hot, but
                                  scientists think it could be about 43,000°F (24,000°C) near Jupiter's
                                  center, or core.
                                </Accordion>
                                <Accordion title="What Is a Black Hole?">
                                  A black hole is an area of such immense gravity that nothing -- not even
                                  light -- can escape from it.
                                </Accordion>
                              </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="d-block d-lg-none">
                <Responsive />
                <Menu />
            </div>
            <Footer />
        </Fragment>
    )
}

export default Faq;