import React from 'react';
// import { Link } from 'react-router-dom';
import "./about-res.scss";

import dots from "../../../assets/dots-bg.png";
import laptop from "../../../assets/fb360laptop-full.png";
import story from "../../../assets/story.png";
import ahead from "../../../assets/ahead.png";

import logoBig from "../../../assets/fb360logo-big.png";
import pin from "../../../assets/icon-pin.png";
import phone from "../../../assets/icon-phone.png";
import mail from "../../../assets/icon-mail.png";
import contact_us from "../../../assets/contact_us.png";
import Global from '../../../Global';

const AboutRes = () => {
    return (
        <div>
            <div className="about-banner-sm">
                <div className="container d-flex flex-column">
                    <div className="dots-bg">
                        <img src={dots} alt="Dots"/>
                    </div>
                    <div className="about-us">
                        <div className="about-title">About us</div>
                        <div className="about-desc">
                            Collecting Perspectives. Including Different Voices & Their Experiences. Starting Point for Dialogue.
                        </div>
                    </div>
                    <div className="laptop-right">
                        <img src={laptop} alt="Laptop"/>
                    </div>
                </div>
            </div>
            
            <div className="vision-about">
                <div className="vision-sub d-flex flex-column align-items-center mx-auto">
                    <div className="vision-title">Our Vision</div>
                    <div className="vision-desc">
                        Making the collecting of viewpoints, perspectives, insights, experiences and feedback easy, useful and relevant given a particular context or focus is what we seek to do. This can provide a positive cornerstone to constructive and inclusive dialogue where progress is sought.<br/><br/>We are inspired – in essence - by the wonderful Singapore Hawker scene which produces an amazing array of diverse, rich and colourful food that is Good, Quick and Cheap. It is also the place of gathering, belonging and the consideration of others around the round table as the food is eaten – together!
                    </div>
                </div>
            </div>
            
            <div className="our-story-abt">
                <div className="d-flex flex-column">
                    <div className="story-img">
                        <img src={story} alt="Story"/>
                    </div>
                    <div className="story-section">
                        <div className="dots-bg">
                            <img src={dots} alt="Dots"/>
                        </div>
                        <div className="story-title">Our Story</div>
                        <div className="story-desc">After many years of coaching (individuals and teams), strategy facilitation and mentoring founders and startups, a constant frustration existed around getting useful input and feedback for leaders. It was never easy. One could buy an expensive 360 instrument (assuming one had been certified after attending an expensive workshop). The questions were many and often set-in stone. One could also conduct interviews – at great cost to the client as well as involving a lot of work for the coach. There were other ways too, but in each case, there was always something that made it all harder than it should have been.<br/><br/>There were a few other challenges that caught our attention. We needed something that allowed an individual to take control of initiating the collecting of feedback while also enabling anonymity protection for various types of raters – like their direct reports. We needed to build into the tool good practice guiderails.<br/><br/>Another challenge was the cost point of 360s and how this was prohibitive for those in developing countries.<br/><br/>So, the journey started.<br/><br/>This is an initiative by a Singapore-based consulting firm, Praxis Management Consulting Pte Ltd, established in 1997.</div>
                    </div>
                </div>
            </div>
            
            <div className="looking-ahead-abt">
                <div className="d-flex flex-column">
                    <div className="ahead-img">
                        <img src={ahead} alt="Ahead"/>
                    </div>
                    <div className="ahead-section">
                        <div className="dots-bg">
                            <img src={dots} alt="Dots"/>
                        </div>
                        <div className="ahead-title">Looking Ahead</div>
                        <div className="ahead-desc">This is the beginning. Much potential exists along the road ahead and we are excited. The depository of questions can grow, access to good coaches could be made easy and AI may help us solicit insights from the data at a later point in time. Privacy and confidentially will always be paramount, so only very high-level aggregate data would be used.  With this, we can bring in relevant and immediately useful pointers, articles and online courses, to name a few opportunities.<br/><br/>We want to continue to ensure that the individuals themselves are the ones who own and control their data and reports rather than the organisations - who often purchase such assessments. Corporately bought reports get lost, but we also want to enable individuals to collect their reports as they journey in their careers so they can truly be the captain of their own career journey. Good coaches would then be able to support even greater learning, while progress can be monitored - and this is an unrecognized, major source of motivation!<br/><br/>As it is for individuals, it can be for teams, ideas/issues and startups.</div>
                    </div>
                </div>
            </div>

            <div id="contact-section-mobile" className="contact-mobile d-flex flex-column">
                <div className="form-contact">
                    <div className="borderGreen" style={{margin:'0px'}}></div>
                    <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                    <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                    <form>
                        <input type="text" name="name" className="form-control" placeholder="Your name" />
                        <input type="email" name="email" className="form-control" placeholder="Email" />
                        <textarea className="form-control" name="message" rows="6" placeholder="Message"></textarea>
                        <input type="submit" className="btn submit-btn" value="Send" />
                    </form>
                </div>
                <div className="address">
                    <div className="list-contact d-flex flex-row">
                        <img src={contact_us} alt="Contact us"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutRes;