import React from 'react';
// import { Link } from 'react-router-dom';
import "./points-res.scss";

// assets
import logoBig from "../../../assets/fb360logo-big.png";
import pin from "../../../assets/icon-pin.png";
import phone from "../../../assets/icon-phone.png";
import mail from "../../../assets/icon-mail.png";
import banner_right from "../../../assets/banner_right_bottom.png";
import banner_left from "../../../assets/banner_left_top.png";
import featured1 from "../../../assets/points/featured1.png";
import featured2 from "../../../assets/points/featured2.png";
import featured3 from "../../../assets/points/featured2.png";
import featured4 from "../../../assets/points/featured2.png";
import community from "../../../assets/points/community.jpg";
import points_bg from "../../../assets/points/points-bg.png";
import contact_us from "../../../assets/contact_us.png";
import Global from '../../../Global';

class PointsRes extends React.Component {
  constructor(props) {
        super(props);
        this.state = { 
            name: '',
            email: '',
            message: ''
        };
    }

    handleSubmit = (event) => {
        var _this = this;
        fetch(Global.WEB_URL + '/api/contact', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function(response) {
            alert('Thank you for submitting your idea.');

             _this.setState({ 
                name: '',
                email: '',
                message: ''
            });

            console.log(response)
            return response.json();
        });

        event.preventDefault();
    }
    render(){
      return (
        <div>
            <div className="banner" style={{backgroundImage: "url(" + points_bg + ")"}}>
          <div className="container">
            <div className="learn-more">
              <div className="dots-bg"></div>
              <div className="learn-title">Focus Points</div>
              <div className="learn-desc">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    The first step towards selecting the questionnaire that is
                    right for you
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bannerRight">
            <img src={banner_right} alt="Banner right" />
          </div>
          <div className="bannerLeft">
            <img src={banner_left} alt="Banner left" />
          </div>
        </div>

        <section className="article">
          <div className="container">
            <div className="row">
              <div className="article-wrapper">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="borderGreen"></div>

                    <div className="title-block">
                      Questionnaire Focal Points
                    </div>

                    <p>
                      Available questionnaires are designed as springboards to
                      your conversations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="container">
            <div className="row">
              <div className="features-wrapper">
                <div className="list">
                  <div className="list-item">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <img src={featured1} alt="Individual" />
                      </div>

                      <div className="col-md-6">
                        <span className="title">Individual</span>

                        <p>
                          If a more traditional 360 feedback exercise is in
                          mind, this is where you would start. The individual
                          would be the focus point of the questionnaire.
                        </p>

                        <p>
                          Depending on the questionnaire chosen, viewpoints
                          offered can help the individual understand others, get
                          insight around how his/her behaviour is interpreted
                          and experienced in various situations, where
                          understanding around communication may differ.
                          Feedforward is always an opportunity.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="row align-items-center">
                      <div className="col-md-6 order-md-2">
                        <img src={featured2} alt="Team" />
                      </div>

                      <div className="col-md-6 order-md-1">
                        <span className="title">Team</span>

                        <p>
                          Value creating teams are the building blocks of
                          organisations. Collecting viewpoints from team members
                          and their stakeholders can be done safely and
                          inclusively ensuring many perspectives are explored.
                        </p>

                        <p>
                          Discussions that can be kickstarted include - the
                          team’s purpose, stakeholder needs and perceptions,
                          primary activities, environment scanning, clarity of
                          strategic objectives and initiatives, priorities, and
                          importantly, ways of working and learning together –
                          to name a few.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <img src={featured3} alt="Issue" />
                      </div>

                      <div className="col-md-6">
                        <span className="title">Idea / Issue</span>

                        <p>
                          Bringing in many different minds and hearts to explore
                          a proposed idea can be of great benefit. Starting
                          discussions about ideas can be turbo-charged when many
                          viewpoints are collected initiatively. These could
                          include how the ideas are understood, their
                          usefulness, and how they could be improved and
                          implemented.
                        </p>

                        <p>
                          It would be similar for issues, challenges, proposed
                          changes, mergers or "wicked problems" that may be
                          imminent or present in one’s organisation. A wide
                          range of viewpoints and perspectives would be
                          extremely helpful for leaders to collect as they plan
                          the best way forward.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="row align-items-center">
                      <div className="col-md-6 order-md-2">
                        <img src={featured4} alt="Startup" />
                      </div>

                      <div className="col-md-6 order-md-1">
                        <span className="title">Startup</span>

                        <p>
                          When developing new products, services, business
                          models, getting quick and frequent feedback is
                          important – especially from customers. Collecting the
                          viewpoints and wisdom of multiple mentors, advisors
                          and potential investors would be a tremendous help to
                          founders and their teams.
                        </p>

                        <p>
                          While founders and their team could also use
                          questionnaires for individuals and teams,
                          questionnaires in this focus point could also be
                          applied to business initiatives and activities in
                          larger organisations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="community">
            <div className="community-wrapper">
                <div className="list">
                    <div className="list-item">
                    <img src={community} alt="Feedback360 Community" />
                    </div>
                    <div className="list-item">
                    <div className="inner">
                        <span className="title"><i>Feedback360</i> Community</span>

                        <p>
                        It is always good when good coaches get together and share
                        their best questions and their experiences within each
                        other. The opportunity for mutual learning is great as
                        coaches seek to better support their clients. <br></br>
                            Encouraging
                        the asking of thought leading research questions, actual
                        research, data collection and the sharing of results is
                        part of our aspirations here.
                        </p>

                        <a href="#" className="btn">Learn more</a>
                    </div>
                    </div>
                </div>
            </div>
        </section>

            <div id="contact-section-mobile" className="contact-mobile d-flex flex-column">
                <div className="form-contact">
                    <div className="borderGreen" style={{margin:'0px'}}></div>
                    <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                    <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                    <form onSubmit={this.handleSubmit}>
                            <input type="text" name="name" className="form-control" placeholder="Your name" value={this.state.name} onChange={this.handleChange}/>
                            <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                            <textarea className="form-control" name="message" rows="6" placeholder="Message" value={this.state.message} onChange={this.handleChange}></textarea>
                            <input type="submit" className="btn submit-btn" value="Send" />
                        </form>
                </div>
                <div className="address">
                    <div className="list-contact d-flex flex-row">
                        <img src={contact_us} alt="Contact us"/>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

export default PointsRes;