import React, { Fragment } from 'react';
import "./resources.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Contact from "../../components/contact/contact";
import Responsive from "../../components/responsive/resources/resources-res";
import Menu from "../../components/menu/menu";

// assets
import banner_right from "../../assets/banner_right_bottom.png";
import banner_left from "../../assets/banner_left_top.png";
import resources1 from "../../assets/resources/resources1.png";
import resources2 from "../../assets/resources/resources2.png";
import resources_right from "../../assets/resources/resources_right.png";
import resources_bg from "../../assets/resources/resources-bg.png";

const Resources = () => {
    return (
        <Fragment>
            <Header />
            <div className="d-none d-lg-block">
                <div className="banner" style={{backgroundImage: "url(" + resources_bg + ")"}}>
                    <div className="container">
                        <div className="learn-more">
                            <div className="dots-bg"></div>
                            <div className="learn-title">Resources</div>
                            <div className="learn-desc">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Have questions? We’ve got answers. Find what you need quickly!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bannerRight">
                        <img src={banner_right} alt="Banner right"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={banner_left} alt="Banner left"/>
                    </div>
                </div>

                <section className="resources">
                    <div className="resources-right">
                        <img src={resources_right} alt="Resources right"/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="borderGreen"></div>

                                <div className="title-block">
                                    Support Resources
                                </div>

                                <ul className="resources-list">
                                    <li>
                                        <a href="/faq">
                                            <i>
                                                <img src={resources1} alt="Help Center" />
                                            </i>
                                            <span>Help Center</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://feedback360online.wordpress.com/blog/" target="_blank">
                                            <i>
                                                <img src={resources2} alt="Feedback360 Blog" />
                                            </i>
                                            <span>Feedback360 Blog</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-5">
                                <div className="borderGreen"></div>

                                <div className="title-block">
                                    Get in Touch
                                </div>

                                <p>
                                    Still need help? Don’t be shy to reach out!
                                </p>

                                <p>
                                    Our team is at your service. If you’d like an awesome product tour, request a personalised demo! They are also available for other questions.
                                </p>

                                <ul className="resources-buttons">
                                    <li>
                                        <a href="https://calendly.com/feedback360/book-your-free-demo-of-feedback360">Book Your Free Demo</a>
                                    </li>

                                    <li>
                                        <a href="/#contact-section">Contact us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <Contact />
            </div>

            <div className="d-block d-lg-none">
                <Responsive />
                <Menu />
            </div>
            <Footer />
        </Fragment>
    )
}

export default Resources;