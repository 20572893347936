import React from 'react';
import { Link, NavLink } from 'react-router-dom';

// assets
import "./footer.scss";
import logo from "../../assets/fb360logo-footer.png";
import phone from "../../assets/icon-phone.png";
import mail from "../../assets/icon-mail.png";
// import contact_us from "../../assets/contact_us.png";
import pin from "../../assets/icon-pin.png";


const Footer = () => {

    return (
        <div>
            <div className="foot d-flex flex-column d-none d-lg-block">
                <div className="upper-foot d-flex flex-row justify-content-between">
                    <div className="about-foot">
                        <div className="foot-logo"><img src={logo} alt="Logo"/></div>
                        <div className="foot-desc">We are inspired – in essence - by the wonderful Singapore Hawker scene which produces an amazing array of diverse, rich and colourful food that is Good, Quick and Cheap.  It is also the place of gathering, belonging and the consideration of others around the round table as the food is eaten – together!  
                        </div>
                        
                    </div>
                    <div className="quick-links">
                        <div className="foot-title">Contact info</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="https://www.google.com/maps/place/3+Coleman+St,+Singapore+179804/data=!4m2!3m1!1s0x31da19a12e1b59b5:0x3a73360cafb1a60f?sa=X&ved=2ahUKEwiE3Jufl9XyAhXV8XMBHUasAysQ8gF6BAgFEAE" traget="_blank"><img className="mx-auto" src={pin} alt="Pin"/> 3 Coleman Street, #03-24, Singapore, 179804</a></li>
                                <li className="list-group-item"><a href="mailto:hello@feedback360online.com"> <img className="mx-auto" src={mail} alt="Mail"/> hello@feedback360online.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="quick-links">
                        <div className="foot-title">Quick links</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="/about">About Feedback360</a></li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/points"
                                        activeClassName="active">
                                        Focus Points
                                    </NavLink>
                                </li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/pricing"
                                        activeClassName="active">
                                        Pricing
                                    </NavLink>
                                </li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/resources"
                                        activeClassName="active">
                                        Resources
                                    </NavLink>
                                </li>
                                <li className="list-group-item"><a href="#">FAQs</a></li>
                                <li className="list-group-item"><a href="#" onClick={function() {document.getElementById("login").click();}}>Login / Sign up</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="support">
                        <div className="foot-title">Support</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="/#contact-section">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="hrFooter"/>
                <div className="copyright mt-auto"><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-use">Terms of Use</a> | Copyright Feedback360 2021</div>
            </div>
            
            <div className="foot-sm d-flex flex-column d-block d-lg-none">
                <div className="upper-foot d-flex flex-column">
                    <div className="about-foot">
                        <div className="foot-logo"><img src={logo} alt="Logo"/></div>
                        <div className="foot-desc mt-5">We are inspired – in essence - by the wonderful Singapore Hawker scene which produces an amazing array of diverse, rich and colourful food that is Good, Quick and Cheap.  It is also the place of gathering, belonging and the consideration of others around the round table as the food is eaten – together!  
                    </div>
                        
                    </div>
                    <div className="quick-links">
                        <div className="foot-title">Contact info</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="#"><img className="mx-auto" src={pin} alt="Pin"/> 1234 Marina bay street, Singapore</a></li>
                                <li className="list-group-item"><a href="#features"><img className="mx-auto" src={phone} alt="Phone"/> (+65) 12344567</a></li>
                                <li className="list-group-item"><a href="#packages"> <img className="mx-auto" src={mail} alt="Mail"/> email@email.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="quick-links">
                        <div className="foot-title">Quick links</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="/about">About Feedback360</a></li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/points"
                                        activeClassName="active">
                                        Focus Points
                                    </NavLink>
                                </li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/pricing"
                                        activeClassName="active">
                                        Pricing
                                    </NavLink>
                                </li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/resources"
                                        activeClassName="active">
                                        Resources
                                    </NavLink>
                                </li>
                                <li className="list-group-item"><a href="#">FAQs</a></li>
                                <li className="list-group-item"><a href="#" onClick={function() {document.getElementById("login").click();}}>Login / Sign up</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="support">
                        <div className="foot-title">Support</div>
                        <div className="foot-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="/#contact-section">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <hr className="hrFooter"/>
                <div className="copyright mt-auto"><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-use">Terms of Use</a><br/>Copyright Feedback360 2021</div>
            </div>
        </div>
        
    )
}

export default Footer;