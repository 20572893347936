import React from 'react';
// import { Link } from 'react-router-dom';
import "./pricing-res.scss";

// assets
import logoBig from "../../../assets/fb360logo-big.png";
import pin from "../../../assets/icon-pin.png";
import phone from "../../../assets/icon-phone.png";
import mail from "../../../assets/icon-mail.png";
import banner_right from "../../../assets/banner_right_bottom.png";
import banner_left from "../../../assets/banner_left_top.png";
import pricing_left from "../../../assets/pricing/pricing-left.png";
import pricing_right from "../../../assets/resources/resources_right.png";
import pricing_bg from "../../../assets/pricing/pricing-bg.png";
import contact_us from "../../../assets/contact_us.png";
import Global from '../../../Global';

class PricingRes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            email: '',
            message: ''
        };
    }

    handleSubmit = (event) => {
        var _this = this;
        fetch(Global.WEB_URL + '/api/contact', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function(response) {
            alert('Thank you for submitting your idea.');

            _this.setState({ 
                name: '',
                email: '',
                message: ''
            });

            console.log(response)
            return response.json();
        });

        event.preventDefault();
    }
    render(){
        return (
        <div>
            <div className="banner" style={{backgroundImage: "url(" + pricing_bg + ")"}}>
                <div className="container">
                    <div className="learn-more">
                        <div className="dots-bg"></div>
                        <div className="learn-title">Pricing</div>
                        <div className="learn-desc">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">A perfect way to kickstart a meaningful conversation with viewpoints gathered</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bannerRight">
                    <img src={banner_right} alt="Banner right"/>
                </div>
                <div className="bannerLeft">
                    <img src={banner_left} alt="Banner left"/>
                </div>
            </div>

            <section className="pricing">
                <div className="pricing-left">
                    <img src={pricing_left} alt="Pricing left"/>
                </div>
                <div className="pricing-right">
                    <img src={pricing_right} alt="Pricing right"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="borderGreen"></div>

                            <span className="pricing-desc-big">
                                Start your risk free trial. No credit card required.
                            </span>

                            <span className="pricing-title-big">From <b>USD 19.90 per report</b></span>
                        </div>
                    </div>
                    
                    <div className="pricing-table">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <span className="pricing-title">Individual</span>

                                <span className="pricing-desc">For individuals to initiate their own gathering of viewpoints</span>

                                <span className="pricing-price">From USD 19.90</span>

                                <a href="#" className="btn">Try for Free</a>

                                <ul>
                                    <li>Access to basic and premium questionnaire</li>
                                    
                                    <li>Set-up questionnaires for yourself</li>

                                    <li>Dashboard to manage and monitor questionnaires</li>

                                    <li>Access to questionnaire reports</li>
                                </ul>
                            </div>

                            <div className="col-12 col-lg-4">
                                <span className="pricing-title">Corporate</span>

                                <span className="pricing-desc">
                                For professional and large organisations who administravely support the gathering of viewpoints for in-house programmes and workshops
                                </span>

                                <span className="pricing-price">From USD 15.90</span>

                                <a href="#" className="btn">Try for Free</a>

                                <p>What an Individual user gets AND :</p>

                                <ul>
                                    <li>Set up questionnaires for other individuals</li>

                                    <li>
                                    Dashboard to manage questionnaires for multiple individuals, workshops, or for broarder application in organisation
                                    </li>
                                    
                                    <li>
                                    Management of questionnaire report distribution (ie. directing reports to the individual and coach)
                                    </li>

                                    <li>
                                    Discounts for bulk purchases
                                    </li>
                                </ul>
                            </div>

                            <div className="col-12 col-lg-4">
                                <span className="pricing-title">Coach</span>

                                <span className="pricing-desc">For professional coaches as they support the people they work with in gathering viewpoints</span>

                                <span className="pricing-price">From USD 15.90</span>

                                <a href="#" className="btn">Try for Free</a>

                                <p>What an Corporate user gets AND :</p>

                                <ul>
                                    <li>Access to propriatery questionnaires, if accredited</li>

                                    <li>Access to the community of coaches for sharing of best practices and mutual learning</li>

                                    <li>Questionnaire contributor status benefits</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="contact-section-mobile" className="contact-mobile d-flex flex-column">
                <div className="form-contact">
                    <div className="borderGreen" style={{margin:'0px'}}></div>
                    <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                    <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                    <form onSubmit={this.handleSubmit}>
                            <input type="text" name="name" className="form-control" placeholder="Your name" value={this.state.name} onChange={this.handleChange}/>
                            <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                            <textarea className="form-control" name="message" rows="6" placeholder="Message" value={this.state.message} onChange={this.handleChange}></textarea>
                            <input type="submit" className="btn submit-btn" value="Send" />
                        </form>
                </div>
                <div className="address">
                    <div className="list-contact d-flex flex-row">
                        <img src={contact_us} alt="Contact us"/>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

export default PricingRes;