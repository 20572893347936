import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import "./fail.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import fail from "../../assets/fail.png";

const Fail = () => {
    return (
        <Fragment>
            <Header/>
            <div className="container">
                <div className="fail text-center mx-auto">
                    <div className="fail-img">
                        <img src={fail} alt="Fail"/>
                    </div>
                    <div className="fail-title">Checkout Failed</div>
                    <div className="fail-desc mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At ut mauris, est est purus lobortis justo bibendum.</div>
                    <div className="fail-btn">
                        <Link to="/"><button className="btn">Back to home</button></Link>
                    </div>
                </div>    
            </div>
            <Menu />
            <Footer/>
        </Fragment>
    )
}

export default Fail;