import React from 'react';
import "./contact.scss";
// import logoBig from "../../assets/fb360logo-big.png";
// import pin from "../../assets/icon-pin.png";
// import phone from "../../assets/icon-phone.png";
// import mail from "../../assets/icon-mail.png";
import contact_us from "../../assets/contact_us.png";
import Global from '../../Global';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            email: '',
            message: ''
        };
    }
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
        var _this = this;
        fetch(Global.WEB_URL + '/api/contact', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function(response) {
            alert('Thank you for submitting your idea.');

            _this.setState({ 
                name: '',
                email: '',
                message: ''
            });

            console.log(response)
            return response.json();
        });

        event.preventDefault();
    }

    render() {
        return (
            <div id="contact-section" className="contact d-flex flex-row">
                <div className="form-contact">
                    <div className="borderGreen" style={{margin:'0px'}}></div>
                    <div className="contact-title">Have ideas? <span>Get in Touch</span></div>
                    <div className="contact-desc">We are always keen to hear your ideas about how we can improve your Feedback360 experience as well as hear about ways in which you are using it.  Please drop us a note with any questions, ideas and valuable learning you have, we'll respond as quickly as we can.</div>
                    <form onSubmit={this.handleSubmit}>
                        <input type="text" name="name" className="form-control" placeholder="Your name" value={this.state.name} onChange={this.handleChange}/>
                        <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                        <textarea className="form-control" name="message" rows="6" placeholder="Message" value={this.state.message} onChange={this.handleChange}></textarea>
                        <input type="submit" className="btn submit-btn" value="Send" />
                    </form>
                </div>
                <div className="address">
                    <div className="list-contact d-flex flex-row">
                        <img src={contact_us} alt="Contact us"/>
                    </div>
                </div>
            </div>
        )
    }
    
}

export default Contact;