import React from 'react';
import "./header.scss";
import logo from "../../assets/logo-fb360.svg";
import logoWhite from "../../assets/fb360logo-footer.png";
import { NavLink } from 'react-router-dom';
import LoginModal from "../../components/common/login/login-modal";
import PreSignupModal from "../../components/common/login/presignup-modal";
import SignupModal from "../../components/common/login/signup-modal";
import ForgetPasswordModal from "../../components/common/login/forgetpassword-modal";

class Header extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            isTop: true,
            showLoginModal: false,
            showPreSignupModal: false,
            showSignupModal: false,
            showForgetPasswordModal: false,
            menuActives: {
                about: false,
                features: false,
                focus: false,
                pricing: false
            }
        };
        this.onScroll = this.onScroll.bind(this);
        this.setActiveNav = this.setActiveNav.bind(this);
    }
    toggleBtn() {
        this.setState({active: !this.state.active});
    }
    goTo(trgt){
        this.setState({active: !this.state.active});
        window.location.href = '/#features-mobile';
    }
    componentDidMount() {
        let _this = this;
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                _this.onScroll(isTop);
            }
        });

    }
    setActiveNav(route){
        if(route == 'features'){
            this.setState({
                menuActives: {
                    about: false,
                    features: true,
                    focus: false,
                    pricing: false
                }
            });
        }else if(route == 'focus'){
            this.setState({
                menuActives: {
                    about: false,
                    features: false,
                    focus: true,
                    pricing: false
                }
            });
        }else if(route == 'pricing'){
            this.setState({
                menuActives: {
                    about: false,
                    features: false,
                    focus: false,
                    pricing: true
                }
            });
        }else{
            this.setState({
                menuActives: {
                    about: false,
                    features: false,
                    focus: false,
                    pricing: false
                }
            });
        }
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    render() {
        return (
            <div>
                {this.state.showLoginModal && (
                    <LoginModal  goToForget={() => {this.setState({showLoginModal: false,showForgetPasswordModal: true})}}  goToPreSignUp={() => {this.setState({showLoginModal: false, showPreSignupModal: true})}} open={this.state.showLoginModal} onClose={() => {this.setState({showLoginModal: false})}}>
                    </LoginModal>
                )}
                {this.state.showPreSignupModal && (
                    <PreSignupModal  goToSignup={() => {this.setState({showSignupModal: true,showPreSignupModal: false})}} goToLogin={() => {this.setState({showLoginModal: true, showPreSignupModal: false})}} open={this.state.showPreSignupModal} onClose={() => {this.setState({showPreSignupModal: false})}}>
                    </PreSignupModal>
                )}
                {this.state.showSignupModal && (
                    <SignupModal goToLogin={() => {this.setState({showLoginModal: true, showSignupModal: false})}} open={this.state.showSignupModal} onClose={() => {this.setState({showSignupModal: false})}}>
                    </SignupModal>
                )}
                {this.state.showForgetPasswordModal && (
                    <ForgetPasswordModal goToLogin={() => {this.setState({showLoginModal: true, showForgetPasswordModal: false})}} open={this.state.showForgetPasswordModal} onClose={() => {this.setState({showForgetPasswordModal: false})}}>
                    </ForgetPasswordModal>
                )}
                <div className={"head d-none d-lg-block " + (this.state.isTop ? 'backgroundTransparent' : 'backgroundWhite')}>
                    <div className="container-fluid bar-group d-flex align-items-center justify-content-between">
                        <div className="bar-logo">
                            <a href="/"><img src={this.state.isTop ? logoWhite : logo } height="45px" style={{ width: '200px',height: 'auto'}} alt="Logo"/></a>
                        </div>
                        <div className="bar-link">
                            
                                {(() => {
                                    if (window.location.href.split('/').pop() == "" || window.location.href.split('/').pop().indexOf('#') > -1) {
                                        return (
                                            <ul className="list-group list-group-flush d-flex flex-row">
                                                <li onClick={()=> this.setActiveNav('about')} className={`list-group-item ${this.state.menuActives.about ? 'active' : ''}`}>
                                                    <a style={{'color': this.state.isTop ? '#fff':'#333'}} href="/about">About Feedback360</a>
                                                </li>
                                                <li onClick={()=> this.setActiveNav('features')} className={`list-group-item ${this.state.menuActives.features ? 'active' : ''}`}><a style={{'color': this.state.isTop ? '#fff':'#333'}} href="/#features" >Features</a></li>
                                                <li onClick={()=> this.setActiveNav('focus')} className={`list-group-item ${this.state.menuActives.focus ? 'active' : ''}`}><a style={{'color': this.state.isTop ? '#fff':'#333'}} href="/points" >Focus Points</a></li>
                                                <li onClick={()=> this.setActiveNav('pricing')} className={`list-group-item ${this.state.menuActives.pricing ? 'active' : ''}`}><a style={{'color': this.state.isTop ? '#fff':'#333'}} href="/pricing" >Pricing</a></li>
                                                <li className="list-group-item"><a style={{'color': this.state.isTop ? '#fff':'#333'}} href="/resources" >Resources</a></li>
                                                <li className="list-group-item bar-button">
                                                    <a id="contactus" className="btn btn-primary" style={{'color': this.state.isTop ? '#fff':'#333','borderColor': this.state.isTop ? '#fff':'#333'}} href="/#contact-section">Contact us</a>
                                                    <button id="login" className="btn btn-primary" onClick={() => this.setState({showLoginModal: !this.state.showLoginModal})}>Login</button>
                                                </li>
                                            </ul>
                                        )
                                    } else {
                                        return (
                                            <ul className="list-group list-group-flush d-flex flex-row">
                                                <li className="list-group-item">
                                                    <NavLink
                                                        to="/about"
                                                        activeClassName="active">
                                                        About Feedback360
                                                    </NavLink>
                                                </li>
                                                <li className="list-group-item">
                                                    <a href="/#features" >Features</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <NavLink
                                                        to="/points"
                                                        activeClassName="active">
                                                        Focus Points
                                                    </NavLink>
                                                </li>
                                                <li className="list-group-item">
                                                    <NavLink
                                                        to="/pricing"
                                                        activeClassName="active">
                                                        Pricing
                                                    </NavLink>
                                                </li>
                                                <li className="list-group-item">
                                                    <NavLink
                                                        to="/resources"
                                                        activeClassName="active">
                                                        Resources
                                                    </NavLink>
                                                </li>
                                                <li className="list-group-item bar-button">
                                                    <a id="contactus" className="btn btn-primary" style={{'color': this.state.isTop ? '#fff':'#333','borderColor': this.state.isTop ? '#fff':'#333'}} href="/#contact-section">Contact us</a>
                                                    <button id="login" className="btn btn-primary" onClick={() => this.setState({showLoginModal: !this.state.showLoginModal})}>Login</button>
                                                </li>
                                            </ul>
                                        )
                                    }
                                })()}
                        </div>
    
                    </div>
                </div>
    
                <div className={"head d-block d-lg-none " + (this.state.isTop ? 'backgroundTransparent' : 'backgroundWhite')}>
                    <div className={`bg-head ${this.state.active? "d-none" : "d-block"}`}>
                        <div className="bar-group-sm d-flex flex-nowrap align-items-center justify-content-between">
                            <div className="bar-logo">
                                <a href="/"><img src={logo} height="40px" alt="Logo"/></a>
                            </div>
                            <div className="bar-menu">
                                <button className="btn btn-primary"><i className="fas fa-bars" onClick={() => this.setState({active: !this.state.active})}></i></button>
                            </div>
                        </div>
                    </div>
                    
                    <div className={`testing-menu modal-fullscreen d-flex flex-column ${this.state.active? "dblock-custom" : "dnone-custom"}`}>
                        <button className="btn btn-primary align-self-end"><i className="fas fa-times" onClick={() => this.setState({active: !this.state.active})}></i></button>
                        
                        <div className="list-menu">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item" onClick={() => this.setState({active: !this.state.active})}><a href="/about">About Feedback360</a></li>
                                <li className="list-group-item" onClick={() => this.setState({active: !this.state.active})}><a href="/#features-mobile">Features</a></li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/points"
                                        activeClassName="active">
                                        Focus Points
                                    </NavLink>
                                </li>
                                <li className="list-group-item">
                                    <NavLink
                                        to="/pricing"
                                        activeClassName="active">
                                        Pricing
                                    </NavLink>
                                </li>
                                <li className="list-group-item" onClick={() => this.setState({active: !this.state.active})}><a href="/#contact-section-mobile">Contact us</a></li>
                                <li className="list-group-item" onClick={() => this.setState({showLoginModal: !this.state.showLoginModal, active: !this.state.active})}><a >Login</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
    
}

export default Header;
