import React from 'react';
import "./menu.scss";
// import { Link } from 'react-router-dom';



const Menu = () => {
    return (
        <div className="testing-menu">
            Test
        </div>
    )
}

export default Menu;