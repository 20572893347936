import React from "react";
import "./login-modal.scss";
import login_left from "../../../assets/login_back.png";
import logo from "../../../assets/fb360logo-big.png";
import logo_google from "../../../assets/login-google.png";
import logo_facebook from "../../../assets/login-facebook.png";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

class ForgetPasswordModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = { 
          password: '',
          email: '',
          password_visible: false,
      };
  }
   
  handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
      alert('Thank you for submitting your idea.');

      fetch('http://feedback360.appreneurs.co/api/login', {
          method: 'POST',
          // We convert the React state to JSON and send it as the POST body
          body: JSON.stringify(this.state)
      }).then(function(response) {
          console.log(response)
          return response.json();
      });

      event.preventDefault();
      //return false;
  }

  render() {
    return (
        <Modal 
          classNames={{
            modal: 'modal',
          }}
          center
          open={this.props.open} 
          onClose={this.props.onClose}>
            <div id="login-section" className="login d-flex flex-row">
                <div className="forgetLeft">
                    
                    <div className="textModalTitle">
                        Relevant<br />
                        For<br />
                        Growth.
                    </div>
                </div>
                <div className="form-login">
                    <img src={logo} />
                    <div className="login-title">Forgotten your password?</div>
                    <p>Don’t worry, we’ll send you a message to help you reset your password.</p>
                    <br/>
                    <form onSubmit={this.handleSubmit}>
                            <div className="login-subtitle">Email address</div>
                            <input type="email" name="email" className="form-control" placeholder="Enter your email" value={this.state.value} onChange={this.handleChange}/>

                        <input type="submit" className="btn submit-btn" value="Continue" />
                    </form>
                    <div className="signup"><span onClick={this.props.goToLogin}>Return to Login</span></div>
                </div>
            </div>
        </Modal>
    );
  }
};

export default ForgetPasswordModal;