import React from "react";
import "./login-modal.scss";
import login_left from "../../../assets/login_back.png";
import logo from "../../../assets/fb360logo-big.png";
import logo_google from "../../../assets/login-google.png";
import logo_facebook from "../../../assets/login-facebook.png";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Global from '../../../Global';

const Tooltip = ({ children, text, ...rest }) => {
  const [show, setShow] = React.useState(false);

  return (
    <div className="tooltip-container">
      <div className={show ? 'tooltip-box visible' : 'tooltip-box'}>
        <b>Individual</b> - For individuals to initiate their own gathering of viewpoints <br/><br/><b>Coach</b> - For professional coaches as they support the people they work with in gathering viewpoints <br/><br/><b>Corporate</b> - For professional and large organisations who administravely support the gathering of viewpoints for in-house programmes and workshops <br/>
        <span className="tooltip-arrow" />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

class SignupModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = { 
          u_role: 0,
          password: '',
          email: '',
          password_visible: false,
          account_type: {
            individual:true,
            coach:false,
            corporate:false,
          }
      };
  }
   
  handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
      alert('Thank you for submitting your idea.');

      fetch('http://feedback360.appreneurs.co/api/login', {
          method: 'POST',
          // We convert the React state to JSON and send it as the POST body
          body: JSON.stringify(this.state)
      }).then(function(response) {
          console.log(response)
          return response.json();
      });

      event.preventDefault();
  }

  render() {
    return (
        <Modal 
          classNames={{
            modal: 'modal',
          }}
          center
          open={this.props.open} 
          onClose={this.props.onClose}>
            <div id="login-section" className="login d-flex flex-row">
                <div className="signupOnlyLeft">
                    
                    <div className="textModalTitle">
                        Relevant<br />
                        For<br />
                        Growth.
                    </div>
                </div>
                <div className="form-login">
                    <img src={logo} />
                    <div className="login-title">Create your account</div>
                    <p>We’ll have you gathering viewpoints in no time.</p>
                    <br/>
                    <form action={`${Global.WEB_URL}/admin/register/new_user`} method="POST" acceptCharset="utf-8">
                        <div className="login-subtitle">Full Name</div>
                        <input type="text" name="u_name" className="form-control" placeholder="Enter your Fullname" />

                        <div className="login-subtitle">Email Address</div>
                        <input type="email" name="u_email" className="form-control" placeholder="Enter your email"/>

                        <div className="login-subtitle">Password</div>
                        <div className="login-password-wrap d-flex flex-row">
                            <input type={this.state.password_visible ? "text" : "password"} name="u_password" className="form-control no-margin-bottom" placeholder="Enter your password" />
                            <i onClick={()=>{this.setState({password_visible: !this.state.password_visible})}} class={this.state.password_visible ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                            
                        </div>
                        <p className="passwordNotes">Use 8 or more characters with a mix of letters, numbers and symbols</p>

                        <div className="login-subtitle">Re-enter Password</div>
                        <div className="login-password-wrap d-flex flex-row">
                            <input type={this.state.password_visible ? "text" : "password"} name="u_repassword" className="form-control no-margin-bottom" placeholder="Re-enter password" />
                            <i onClick={()=>{this.setState({password_visible: !this.state.password_visible})}} class={this.state.password_visible ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                        </div>
                        <br/>

                        <div className="login-subtitle">Account Type <Tooltip text="Simple tooltip"><span className="fas fa-question-circle"></span></Tooltip></div>
                        <div className="accountType">
                          <button type="button" onClick={()=> this.setState({account_type: {individual: true, coach: false, corporate: false}, u_role: 2})} className={`${this.state.account_type.individual ? 'active' : ''}`}>Individual</button>
                          <button type="button" onClick={()=> this.setState({account_type: {individual: false, coach: true, corporate: false}, u_role: 3})} className={`${this.state.account_type.coach ? 'active' : ''}`}> Coach</button>
                          <button type="button" onClick={()=> this.setState({account_type: {individual: false, coach: false, corporate: true}, u_role: 4})} className={`${this.state.account_type.corporate ? 'active' : ''}`}>Corporate</button>
                          <input type="hidden" name="u_role" value={this.state.u_role}/>
                        </div>

                        <input type="submit" className="btn submit-btn" value="Get Started!" />
                    </form>
                    <div className="signup">By signing up, you agree to Feedback360’s <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a></div><br/>
                    <div className="signup">Already signed up? <span onClick={this.props.goToLogin}>Log in</span></div>
                </div>
            </div>
        </Modal>
    );
  }
};

export default SignupModal;