import './App.scss';
import React from 'react';
import { Route, Switch,useLocation, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/scrolltop/scrolltop";
import Home from "./pages/homepage/home";
import About from "./pages/about/about";
import Checkout from "./pages/checkout/checkout";
import Success from "./pages/success/success";
import Fail from "./pages/fail/fail";
import Resources from "./pages/resources/resources";
import Pricing from "./pages/pricing/pricing";
import Points from "./pages/points/points";
import Terms from "./pages/terms/terms";
import Privacy from "./pages/terms/privacy";
import Faq from "./pages/faq/faq";

function App() {
  return (
    <div className="base">
        <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/fail">
                <Fail />
              </Route>
              <Route path="/success">
                <Success />
              </Route>
              <Route path="/checkout">
                <Checkout />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/resources">
                <Resources />
              </Route>
              <Route path="/privacy-policy">
                <Privacy />
              </Route>
              <Route path="/terms-of-use">
                <Terms />
              </Route>
              <Route path="/pricing">
                <Pricing />
              </Route>
              <Route path="/points">
                <Points />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/">
                <Home />
              </Route>
              
            </Switch>
        </Router>
    </div>
  );
}

export default App;
