import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import "./success.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";

import success from "../../assets/success.png";

const Success = () => {
    return (
        <Fragment>
            <Header/>
            <div className="container">
                <div className="success text-center mx-auto">
                    <div className="success-img">
                        <img src={success} alt="Success"/>
                    </div>
                    <div className="success-title">Checkout Success</div>
                    <div className="success-desc mx-auto">Your transaction is Success please check your email for account confirmation</div>
                    <div className="success-btn">
                        <Link to="/"><button className="btn">Back to home</button></Link>
                    </div>
                </div>    
            </div>
            <Menu />
            <Footer/>
        </Fragment>
    )
}

export default Success;