import React, { Fragment } from 'react';
import "./about.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Contact from "../../components/contact/contact";
import Responsive from "../../components/responsive/about/about-res";
import Menu from "../../components/menu/menu";

import banner_right from "../../assets/banner_right_bottom.png";
import banner_left from "../../assets/banner_left_top.png";
import dots from "../../assets/dots-bg.png";
import laptop from "../../assets/fb360laptop-right.png";
import story from "../../assets/story.png";
import ahead from "../../assets/ahead.png";

const About = () => {
    return (
        <Fragment>
            <Header />
            <div className="d-none d-lg-block">
                <div className="banner">
                    <div className="container">
                        <div className="learn-more">
                            <div className="dots-bg"></div>
                            <div className="learn-title">About us</div>
                            <div className="learn-desc">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Collecting Perspectives. Including Different Voices & Their Experiences. Starting Point for Dialogue.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bannerRight">
                        <img src={banner_right} alt="Banner right"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={banner_left} alt="Banner left"/>
                    </div>
                </div>

                {/*<div className="about-banner">
                    <div className="grouping d-flex flex-row justify-content-between">
                        <div className="dots-bg">
                            <img src={dots} alt="Dots"/>
                        </div>
                        <div className="about-us">
                            <div className="about-title">About us</div>
                            <div className="about-desc">
                                Collecting Perspectives. Including Different Voices & Their Experiences. Starting Point for Dialogue.
                            </div>
                        </div>
                        <div className="laptop-right">
                            <img src={laptop} alt="Laptop"/>
                        </div>
                    </div>
                    <div className="bannerRight">
                        <img src={banner_right} alt="Banner right"/>
                    </div>
                    <div className="bannerLeft">
                        <img src={banner_left} alt="Banner left"/>
                    </div>
                </div>*/}
                
                <div className="vision">
                    <div className="vision-sub d-flex flex-column align-items-center mx-auto">
                        <div className="vision-title">Our Vision</div>
                        <div className="vision-desc">
                            Making the collecting of viewpoints, perspectives, insights, experiences and feedback easy, useful and relevant given a particular context or focus is what we seek to do. This can provide a positive cornerstone to constructive and inclusive dialogue where progress is sought. Tweak/rework concepts of “good practices, processes and good habits” into these first couple sentences<br/><br/>We are inspired – in essence - by the wonderful Singapore Hawker scene which produces an amazing array of diverse, rich and colourful food that is Good, Quick and Cheap (insert hokkien as available here). It is also the place of gathering, belonging and the consideration of others around the round table as the food is eaten – together!
                        </div>
                    </div>
                </div>
                
                <div className="our-story">
                    <div className="grouping d-flex flex-row justify-content-between">
                        <div className="story-img">
                            <img src={story} alt="Story"/>
                        </div>
                        <div className="story-section">
                            <div className="dots-bg">
                                <img src={dots} alt="Dots"/>
                            </div>
                            <div className="story-title">Our Story</div>
                            <div className="story-desc">After many years of coaching (individuals and teams), strategy facilitation and mentoring founders and startups, a constant frustration existed around getting useful input and feedback for leaders. It was never easy. One could buy an expensive 360 instrument (assuming one had been certified after attending an expensive workshop). The questions were many and often set-in stone. One could also conduct interviews – at great cost to the client as well as involving a lot of work for the coach. There were other ways too, but in each case, there was always something that made it all harder than it should have been.<br/><br/>There were a few other challenges that caught our attention. We needed something that allowed an individual to take control of initiating the collecting of feedback while also enabling anonymity protection for various types of raters – like their direct reports. We needed to build into the tool good practice guiderails.<br/><br/>Another challenge was the cost point of 360s and how this was prohibitive for those in developing countries.<br/><br/>So, the journey started.<br/><br/>This is an initiative by a Singapore-based consulting firm, Praxis Management Consulting Pte Ltd, established in 1997.</div>
                        </div>
                    </div>
                </div>
                
                <div className="looking-ahead">
                    <div className="grouping d-flex flex-row justify-content-between">
                        <div className="ahead-section">
                            <div className="dots-bg">
                                <img src={dots} alt="Dots"/>
                            </div>
                            <div className="ahead-title">Looking Ahead</div>
                            <div className="ahead-desc">This is the beginning. Much potential exists along the road ahead and we are excited. The depository of questions can grow, access to good coaches could be made easy and AI may help us solicit insights from the data at a later point in time. Privacy and confidentially will always be paramount, so only very high-level aggregate data would be used.  With this, we can bring in relevant and immediately useful pointers, articles and online courses, to name a few opportunities.<br/><br/>We want to continue to ensure that the individuals themselves are the ones who own and control their data and reports rather than the organisations - who often purchase such assessments. Corporately bought reports get lost, but we also want to enable individuals to collect their reports as they journey in their careers so they can truly be the captain of their own career journey. Good coaches would then be able to support even greater learning, while progress can be monitored - and this is an unrecognized, major source of motivation!<br/><br/>As it is for individuals, it can be for teams, ideas/issues and startups.</div>
                        </div>
                        
                        <div className="d-flex flex-column">
                            <div className="ahead-img">
                                <img src={ahead} alt="Ahead"/>
                            </div>
                            <div id="contact-section-2"></div>
                            <div className="dots-btm">
                                <img src={dots} alt="Dots"/>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>

                <Contact />
            </div>

            <div className="d-block d-lg-none">
                <Responsive />
                <Menu />
            </div>
            <Footer />
        </Fragment>
    )
}

export default About;